/**
 * Created by Joerg on 15.08.2017.
 */

import {Subject,Subscription} from 'rxjs/Rx';

import {Component,Injectable,OnInit,OnDestroy} from "@angular/core";
import {MnGridParams} from "@mn/gridlazy";


@Component({
    selector: 'mn-grid-lazy-test',
    template: `test`
})
export class MnGridLazyTest  implements OnInit {

    mParamsChange:Subscription;

    constructor(private mParams:MnGridParams) {
        this.mParamsChange = this.mParams.subscribe((params)=> this.onParamsChange(params));
    }

    ngOnInit() {
        //console.log(this.mParams.params)
    }

    ngOnDestroy() {
        console.log(`Destroying lazy component`);
        this.mParamsChange.unsubscribe();
    }

    private onParamsChange(params) {
        console.log("Params changed",params);
    }

}
/**
 * Created by Joerg on 29.07.2017.
 */

/**
 * Created by Joerg on 18.07.2017.
 */

import {Component,ComponentFactoryResolver,ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'mn-test-grid',
    template: `
        <div mnCol class="mn-layout-row">
            <div mnRow> 
                <mn-grid style="height: 100%; width: 100%" [cols]="cols" [rows]="data" theme="ag-mine"></mn-grid>
            </div>
        </div>
    `,
    styleUrls: ['./my-theme.css'],
    encapsulation: ViewEncapsulation.None
})
export class MnTestGrid {

    public cols = [
        {
            type: 'basic',
            label: 'Test basic',
            field: 'row'
        },
        {
            type: 'basic.number',
            label: 'Test basic.number',
            field: 'value'
        },
        {
            type: 'lazy',
            label: 'Lazy',
            field: 'value',
            lazy: { lib: '@mn/core', mod: 'MnCoreModule', comp: 'TestComponent' }
        },
        {
            type: 'lazy',
            label: 'Lazy',
            field: 'value',
            lazy: { lib: '@mn/grid', mod: 'MnGridModule', comp: 'MnGridLazyTest' }
        },
        {
            type: 'basic.number',
            label: 'Test basic.number',
            field: 'currency'
        },
        {
            type: '',
            label: 'Group',
            group: [
                {
                    type: 'basic',
                    label: 'Test basic show always',
                    field: 'row'
                },
                {
                    type: 'basic',
                    label: 'Test basic closed',
                    field: 'row',
                    state: 'closed'
                },
                {
                    type: 'basic.number',
                    label: 'Test basic.number open',
                    field: 'value',
                    state: 'open'
                },
                {
                    type: 'basic.number',
                    label: 'Test basic.number open',
                    field: 'currency',
                    state: 'open'
                },
            ]
        }
    ];

    public data = [];

    constructor(private _componentFactoryResolver: ComponentFactoryResolver) {
        console.log(_componentFactoryResolver);
        this.data = this.createRowData();
    }

    private createRowData() {
        let rowData: any[] = [];

        for (var i = 0; i < 15; i++) {
            rowData.push({
                row: "Row " + i,
                value: i,
                currency: i + Number(Math.random().toFixed(2))
            });
        }

        return rowData;
    }

}

/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

//import {AgGridModule} from 'ag-grid-angular/main'; // does not work and causes rollupts2 to generate d.ts files in wrong folder
import {AgGridModule} from 'ag-grid-angular/dist/aggrid.module';

// mn
import {MnCoreModule} from "@mn/core";
import {MnGridLazyModule} from "@mn/gridlazy";

import {MnGrid} from "./MnGrid";
import {MnGridLazyTest} from "./MnGridLazyTest";
import {MnTestGrid} from "./MnTestGrid";

@NgModule({
    imports: [
        CommonModule,
        MnCoreModule,
        MnGridLazyModule,
        AgGridModule.withComponents([]),
    ],
    exports: [
        MnGrid, MnGridLazyModule, AgGridModule
    ],
    declarations: [
        MnGrid, MnTestGrid, MnGridLazyTest
    ],
    entryComponents: [
        MnGrid, MnTestGrid, MnGridLazyTest
    ]
})
export class MnGridModule {
    static forRoot(): MnGridModule {
        return {
            ngModule: MnGridModule
            //providers: []
        };
    }
}

export class mn_grid {
    static configure():any {
        return [
            MnGridModule
            /*MnRouteModule.forRoot(),
            UIRouterModule.forRoot({
                states: [],
                useHash: true
            })*/
        ];
    }
}
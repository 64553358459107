/**
 * Created by Joerg on 20.07.2017.
 */

import {Component,Input,Output,EventEmitter} from '@angular/core';

import {GridOptions,GridApi,ColumnApi} from 'ag-grid/main'

import * as numeral from 'numeral';
import {MnGridLazy} from "@mn/gridlazy";


/*
let fColNumber = function(col_num:IColNumber, cid:string):any {
    var result:any = fColAny(col_num,cid);
    if (col_num.format && typeof col_num.format === "string") {
        result.valueFormatter = function (params:any) {
            return numeral(params.value).format(col_num.format);
        }
    } else if (col_num.format) {
        result.valueFormatter = col_num.format;
    }
    result.cellStyle = function(params) {
        return {backgroundColor: 'hsl('+ (240 + (60 * params.value / 20) ) +', 100%, 75%)'};
    };
    return result;
};
*/

function assignStringOrFunction(source:any, source_attribute:string, target:any, target_attribute_string:string, target_attribute_function) {
    if (source[source_attribute] && typeof source[source_attribute] === "string") {
        target[target_attribute_string] = source[source_attribute];
    } else if (source[source_attribute]) {
        target[target_attribute_function] = source[source_attribute];
    }
}

export interface MnColType {
    type: string;
    id?: string;
    field?: string;
    label?: string;
    group?: any;
    state?: string;
    header?: string;
    footer?: string;
    cell?: string;
    features?: any;
    config?: any;
    lazy?: any;
}

let cell_renderers = {
    '': {
        '': function(col:MnColType, gcol:any):void {
            gcol.valueGetter = function(params:any):any {
                return "no cell renderer for column " + JSON.stringify(col,null,2);
            };
        }
    },
    'basic': {
        '': function(col:MnColType, gcol:any):void {
            assignStringOrFunction(col,'field', gcol, 'field', 'valueGetter');
        }
    },
    'basic.number': {
        '': function(col:MnColType, gcol:any):void {
            assignStringOrFunction(col,'field', gcol, 'field', 'valueGetter');
            gcol.cellStyle = {
                textAlign: 'right',
                fontFamily: 'monospace'
            }
        }
    },
    'lazy': {
        '': function(col:MnColType, gcol:any):void {
            assignStringOrFunction(col,'field', gcol, 'field', 'valueGetter');
            gcol.cellRendererFramework = MnGridLazy;
            gcol.lazy = col.lazy;
        }
    },
    'compound': {
        '': function(col:MnColType, gcol:any):void {
            assignStringOrFunction(col,'field', gcol, 'field', 'valueGetter');
            gcol.cellRendererFramework = MnGridLazy;
            gcol.lazy = col.lazy;
        }
    },
    /*'basic.number.int': {},
    'basic.number.float': {},
    'basic.string': {},
    'basic.string.cas': {},
    'basic.string.formula': {},
    'basic.boolean': {},
    'basic.category': {},
    'array': {},
    'action': {}*/

};

let header_renderers = {
    '': {
        '': function(col:MnColType, gcol:any):void {
            if (col.label) {
                gcol.headerName = col.label
            } else if (col.field && typeof col.field === 'string') {
                gcol.headerName = col.field
            } else {
                gcol.headerName = 'unknown '+gcol.xid;
            }
        }
    }
};

let footer_renderers = {
    '': {
        'generic': {}
    }
};

interface MnGridFeatrues {
    move:boolean;
}


@Component({
    selector: 'mn-grid',
    templateUrl: './MnGrid.html'
})
export class MnGrid {

    @Input() set "theme" (value:any) {
        this.mClasses = value;
    }
    @Input() set "cols" (value:any) {
        console.log("colschange",value);
        this.mInputCols = value;
        this.mCols = this.createColumnDefs(value);
        //this.mAgGrid.columnDefs = this.createColumnDefs(value);
    }
    @Input() set "rows" (value:any) {
        console.log("datachenge",value);
        this.mData = value;
        //this.mAgGrid.rowData = value;
    }
    @Input() set "features" (value:any) { this.mFeatures = value; this.updateFeatures(); }

    @Output() public gridReady: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnEverythingChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public newColumnsLoaded: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnPivotModeChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnRowGroupChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnPivotChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public gridColumnsChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnValueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnMoved: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnVisible: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnPinned: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnGroupOpened: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnResized: EventEmitter<any> = new EventEmitter<any>();
    @Output() public displayedColumnsChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public virtualColumnsChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public rowGroupOpened: EventEmitter<any> = new EventEmitter<any>();
    @Output() public rowDataChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public floatingRowDataChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public rangeSelectionChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnRowGroupAddRequest: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnRowGroupRemoveRequest: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnPivotAddRequest: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnPivotRemoveRequest: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnValueAddRequest: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnValueRemoveRequest: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnAggFuncChangeRequest: EventEmitter<any> = new EventEmitter<any>();
    @Output() public clipboardPaste: EventEmitter<any> = new EventEmitter<any>();
    @Output() public modelUpdated: EventEmitter<any> = new EventEmitter<any>();
    @Output() public cellClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() public cellDoubleClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() public cellContextMenu: EventEmitter<any> = new EventEmitter<any>();
    @Output() public cellValueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public cellFocused: EventEmitter<any> = new EventEmitter<any>();
    @Output() public rowSelected: EventEmitter<any> = new EventEmitter<any>();
    @Output() public selectionChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public filterChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public filterModified: EventEmitter<any> = new EventEmitter<any>();
    @Output() public sortChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public virtualRowRemoved: EventEmitter<any> = new EventEmitter<any>();
    @Output() public rowClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() public rowDoubleClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() public gridSizeChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public viewportChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public dragStarted: EventEmitter<any> = new EventEmitter<any>();
    @Output() public dragStopped: EventEmitter<any> = new EventEmitter<any>();
    @Output() public itemsAdded: EventEmitter<any> = new EventEmitter<any>();
    @Output() public itemsRemoved: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnRowGroupChangeRequest: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnPivotChangeRequest: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnValueChangeRequest: EventEmitter<any> = new EventEmitter<any>();
    @Output() public rowValueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public bodyScroll: EventEmitter<any> = new EventEmitter<any>();
    @Output() public rowEditingStarted: EventEmitter<any> = new EventEmitter<any>();
    @Output() public rowEditingStopped: EventEmitter<any> = new EventEmitter<any>();
    @Output() public cellEditingStarted: EventEmitter<any> = new EventEmitter<any>();
    @Output() public cellEditingStopped: EventEmitter<any> = new EventEmitter<any>();
    @Output() public displayedColumnsWidthChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public scrollVisibilityChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public flashCells: EventEmitter<any> = new EventEmitter<any>();
    @Output() public cellMouseOver: EventEmitter<any> = new EventEmitter<any>();
    @Output() public cellMouseOut: EventEmitter<any> = new EventEmitter<any>();
    @Output() public columnHoverChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public paginationReset: EventEmitter<any> = new EventEmitter<any>();
    @Output() public paginationPageLoaded: EventEmitter<any> = new EventEmitter<any>();
    @Output() public paginationPageRequested: EventEmitter<any> = new EventEmitter<any>();
    @Output() public paginationChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public bodyHeightChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() public componentStateChanged: EventEmitter<any> = new EventEmitter<any>();

    public mInputCols;
    public mCols;
    public mData;
    public mFeatures:any = {};
    public mClasses = ['ag-fresh'];
    public mAgGrid:GridOptions;

    static registerColumnRenderer(renderer:any, type:string, name:string) {
        if (!cell_renderers[type]) {
            cell_renderers[type] = {};
        }
        cell_renderers[type][name] = renderer;
    }

    constructor() {
        this.mAgGrid = <GridOptions>{
            context: {
                componentParent: this
            }
        };
        this.updateFeatures();
    }

    public aggrid():GridApi {
        return this.mAgGrid.api;
    }

    public agcolumn():ColumnApi {
        return this.mAgGrid.columnApi;
    }

    private updateFeatures() {
        var f = this.mFeatures;
        var g:GridOptions = this.mAgGrid;

        g.suppressMovableColumns = f.move || false;
        g.enableColResize = f.resize || true;
        g.enableSorting = f.sort || true;
        g.enableFilter = f.filter || true;
        if (f.row) {
            g.rowHeight = f.row.height || 25;
        }
        /*if (f.select) {
            if (f.select.mode == "singleclick")
        }*/
        if (f.raw) {
            Object.assign(g,f.raw);
        }
    }

    private bestMatch(type:string, renderer:string, types:any):any {
        var match_t = "";// type match
        for (var key_t in types) {
            if(!types.hasOwnProperty(key_t)) continue;
            if ((<any> type).startsWith(key_t)) {
                if (key_t.length > match_t.length) {
                    match_t = key_t;
                }
            }
        }
        var renderers = types[match_t];
        var match_r = "";// renderer match
        if (renderer) {
            for (var key_r in renderers) {
                if(!renderers.hasOwnProperty(key_r)) continue;
                if (key_r == renderer) {
                    match_r = key_r;
                    break;
                }
            }
        }
        return types[match_t][match_r];
    }

    private parseColumns(cols:any, gcols:any, pcid:string) {
        for (var i = 0, l = cols.length; i < l; i++) {
            var c = cols[i];
            var cid = c.id ? c.id : (pcid+'.'+i);
            var gc:any = {
                xid: cid
            };
            if (c.group) {
                gc.groupId = cid;
                gc.children = [];
                gc.marryChildren = true;
                var ch = this.bestMatch(c.type, c.header, header_renderers);
                ch(c,gc);
                this.parseColumns(c.group,gc.children,pcid);
            } else {
                gc.colId = cid;
                var ch = this.bestMatch(c.type, c.header, header_renderers);
                ch(c,gc);
                var cc = this.bestMatch(c.type, c.cell, cell_renderers);
                cc(c,gc);
            }
            if (c.state) {
                gc.columnGroupShow = c.state;
            }
            if (c.features) {
                var f= c.features;
                if (f.width && f.width.value) gc.width = f.width.value;
                if (f.width && f.width.min) gc.minWidth = f.width.min;
                if (f.width && f.width.max) gc.maxWidth = f.width.max;
            }
            if (c.raw) {
                Object.assign(gc,c.raw);
            }
            gcols.push(gc);
        }
    }

    private createColumnDefs(cols:any) {
        var gcols = [];
        this.parseColumns(cols,gcols,'@');
        return gcols;
    }

    onGridReady($event) { this.gridReady.emit($event); }
    onColumnEverythingChanged($event) { this.columnEverythingChanged.emit($event); }
    onNewColumnsLoaded($event) { this.newColumnsLoaded.emit($event); }
    onColumnPivotModeChanged($event) { this.columnPivotModeChanged.emit($event); }
    onColumnRowGroupChanged($event) { this.columnRowGroupChanged.emit($event); }
    onColumnPivotChanged($event) { this.columnPivotChanged.emit($event); }
    onGridColumnsChanged($event) { this.gridColumnsChanged.emit($event); }
    onColumnValueChanged($event) { this.columnValueChanged.emit($event); }
    onColumnMoved($event) { this.columnMoved.emit($event); }
    onColumnVisible($event) { this.columnVisible.emit($event); }
    onColumnPinned($event) { this.columnPinned.emit($event); }
    onColumnGroupOpened($event) { this.columnGroupOpened.emit($event); }
    onColumnResized($event) { this.columnResized.emit($event); }
    onDisplayedColumnsChanged($event) { this.displayedColumnsChanged.emit($event); }
    onVirtualColumnsChanged($event) { this.virtualColumnsChanged.emit($event); }
    onRowGroupOpened($event) { this.rowGroupOpened.emit($event); }
    onRowDataChanged($event) { this.rowDataChanged.emit($event); }
    onFloatingRowDataChanged($event) { this.floatingRowDataChanged.emit($event); }
    onRangeSelectionChanged($event) { this.rangeSelectionChanged.emit($event); }
    /*onColumnRowGroupAddRequest($event) { this.columnRowGroupAddRequest.emit($event); }
    onColumnRowGroupRemoveRequest($event) { this.columnRowGroupRemoveRequest.emit($event); }
    onColumnPivotAddRequest($event) { this.columnPivotAddRequest.emit($event); }
    onColumnPivotRemoveRequest($event) { this.columnPivotRemoveRequest.emit($event); }
    onColumnValueAddRequest($event) { this.columnValueAddRequest.emit($event); }
    onColumnValueRemoveRequest($event) { this.columnValueRemoveRequest.emit($event); }
    onColumnAggFuncChangeRequest($event) { this.columnAggFuncChangeRequest.emit($event); }*/
    //onClipboardPaste($event) { this.clipboardPaste.emit($event); }
    onModelUpdated($event) { this.modelUpdated.emit($event); }
    onCellClicked($event) { this.cellClicked.emit($event); }
    onCellDoubleClicked($event) { this.cellDoubleClicked.emit($event); }
    onCellContextMenu($event) { this.cellContextMenu.emit($event); }
    onCellValueChanged($event) { this.cellValueChanged.emit($event); }
    onCellFocused($event) { this.cellFocused.emit($event); }
    onRowSelected($event) { this.rowSelected.emit($event); }
    onSelectionChanged($event) { this.selectionChanged.emit($event); }
    onFilterChanged($event) { this.filterChanged.emit($event); }
    onFilterModified($event) { this.filterModified.emit($event); }
    onSortChanged($event) { this.sortChanged.emit($event); }
    onVirtualRowRemoved($event) { this.virtualRowRemoved.emit($event); }
    onRowClicked($event) { this.rowClicked.emit($event); }
    onRowDoubleClicked($event) { this.rowDoubleClicked.emit($event); }
    onGridSizeChanged($event) { this.gridSizeChanged.emit($event); }
    onViewportChanged($event) { this.viewportChanged.emit($event); }
    onDragStarted($event) { this.dragStarted.emit($event); }
    onDragStopped($event) { this.dragStopped.emit($event); }
    onItemsAdded($event) { this.itemsAdded.emit($event); }
    onItemsRemoved($event) { this.itemsRemoved.emit($event); }
    /*onColumnRowGroupChangeRequest($event) { this.columnRowGroupChangeRequest.emit($event); }
    onColumnPivotChangeRequest($event) { this.columnPivotChangeRequest.emit($event); }
    onColumnValueChangeRequest($event) { this.columnValueChangeRequest.emit($event); }*/
    onRowValueChanged($event) { this.rowValueChanged.emit($event); }
    onBodyScroll($event) { this.bodyScroll.emit($event); }
    onRowEditingStarted($event) { this.rowEditingStarted.emit($event); }
    onRowEditingStopped($event) { this.rowEditingStopped.emit($event); }
    onCellEditingStarted($event) { this.cellEditingStarted.emit($event); }
    onCellEditingStopped($event) { this.cellEditingStopped.emit($event); }
    onDisplayedColumnsWidthChanged($event) { this.displayedColumnsWidthChanged.emit($event); }
    onScrollVisibilityChanged($event) { this.scrollVisibilityChanged.emit($event); }
    onFlashCells($event) { this.flashCells.emit($event); }
    onCellMouseOver($event) { this.cellMouseOver.emit($event); }
    onCellMouseOut($event) { this.cellMouseOut.emit($event); }
    onColumnHoverChanged($event) { this.columnHoverChanged.emit($event); }
    onPaginationReset($event) { this.paginationReset.emit($event); }
    onPaginationPageLoaded($event) { this.paginationPageLoaded.emit($event); }
    onPaginationPageRequested($event) { this.paginationPageRequested.emit($event); }
    onPaginationChanged($event) { this.paginationChanged.emit($event); }
    onBodyHeightChanged($event) { this.bodyHeightChanged.emit($event); }
    onComponentStateChanged($event) { this.componentStateChanged.emit($event); }

}

